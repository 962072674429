import React, { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import axios from "axios";
import ReactPixel from 'react-facebook-pixel';

// Imagens
import Dinheiro from '../../assets/img/dinheiro.png';
import Banco from '../../assets/img/banco.png';
import Bolo from '../../assets/img/bolo.png';
import LodingIcon from '../../assets/img/loading-icon.png';

// Components
import Swal from 'sweetalert2';
import MenuFooter from "../../components/menu-footer";
import { swalSemAutorizacao } from "../../components/notify/SemAutorizacao";
import { swalSemAdesao } from "../../components/notify/SemAdesao";
import { swalOperacaoIndisponivel } from "../../components/notify/OperacaoIndisponivel";
import { swalSemSaldo } from "../../components/notify/SemSaldo";
import { swalErroSimulacao } from "../../components/notify/ErroSimulacao";
import { swalPropostaAndamento } from "../../components/notify/PropostaAndamento";
import { AutenticacaoInvalida } from "../../components/notify/AutenticacaoInvalida";

// Tools
import { PrimeiroNome } from '../../tools/PrimeiroNome';

const Autorizacao = () => {

  // Trackview
  ReactPixel.trackCustom('ClicouSaque');

  const primeiroNome = PrimeiroNome(sessionStorage.getItem('nome'));

  const [loading, setLoading] = useState(false);
  const elementLoading = <div id="loader">
    <img src={LodingIcon} alt="icon" className="loading-icon" />
  </div>;

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  // Swal Autorização
  async function SwalAutorizacao() {
    Swal.fire({
      // icon: 'info',
      // title: `${primeiroNome},`,
      showCancelButton: false,
      confirmButtonText: `Já autorizei`,
      confirmButtonColor: '#28a745',
      showCloseButton: true,
      showDenyButton: true,
      denyButtonText:'Acessar App do FGTS',
      denyButtonColor: '#1741C4 ',
      // cancelButtonText: `Não quero consultar!`,
      reverseButtons: true,
      
      html: `<p align="left"><span class="fs-5">${primeiroNome}</span>, para realizar a sua simulação precisamos fazer uma breve consulta em sua conta FGTS.
      <br><br>Vamos verificar se os bancos  possuem autorização para simular e se você possui saldo disponível para antecipação.</p>`,
      allowOutsideClick: false,
      customClass:{
        denyButton:'button-size',
        confirmButton: 'button-size'
      }
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        // Trackview
        ReactPixel.trackCustom('AutorizouConsulta');

        simularFgts();
      }
      // Não tem interesse
      if (result.isDismissed) {

        // Trackview
        ReactPixel.trackCustom('NaoQueroConsulta');

        //swalNaoInteresse();
      }
      if(result.isDenied){
        window.open('https://play.google.com/store/apps/details?id=br.gov.caixa.fgts.trabalhador','_blank')
      }
    });
  }

  // Simulação FGTS
  async function simularFgts() {

    // Inicia Loading
    setLoading(true);

    // Token
    const token = sessionStorage.getItem('identify');

    var config = {
      method: 'POST',
      url: process.env.REACT_APP_URL_API + `/simulacao`,
      data: {
        'tipo_simulacao':'fgts'
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(async function (response) {

        // Para Loading
        setLoading(false);

        // Desfragmentação do response
        const {
          data
        } = response;

        // Simulação gerada
        if (data && data.uuid_simulacao) {

          // Registrando as simulações na session storage
          sessionStorage.setItem('obj_simulacao', JSON.stringify(data));

          // Retorno das simulações
          const simulacoes = data.simulacoes ? data.simulacoes : null;
          const uuid = data.uuid_simulacao;

          // Redirecionamento
          window.location = `/contratacao/${uuid}`;

        }
        // Sem Autorização
        else if (data && data.sem_autorizacao) {
          swalSemAutorizacao(primeiroNome);
        }
        // Sem Adessão
        else if (data && data.sem_adesao) {
          swalSemAdesao(primeiroNome);
        }
        // Operação Indisponivel
        else if (data && data.operacao_indisponivel) {
          swalOperacaoIndisponivel(primeiroNome, data.mensagem);
        }
        // Sem Saldo
        else if (data && data.sem_saldo) {
          swalSemSaldo(primeiroNome);
        }
        // Proposta em andamento
        else if (data && data.proposta_andamento) {
          swalPropostaAndamento(primeiroNome);
        }
        // Blacklist
        else if(data && data.blacklist){
          Swal.fire({
            // icon: 'info',
            // title: `😕 !`,
            html: `<p align="left"><strong class="fs-5">${primeiroNome}</strong>, Acabei de verificar que você fez uma simulação recentemente conosco, já estou te encaminhando para nosso atendente continuar o seu atendimento. 😁</p>`,
            confirmButtonColor: '#4a68b0',
            confirmButtonText: `Falar com atendente`,
            allowOutsideClick: false
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.close();
              window.location = 'https://api.whatsapp.com/send?phone=551433138461';
            }
          });
        }
        // Erro
        else if ((data && data.erro) || (data.httpcode && data.httpcode === 500)) {
          swalErroSimulacao(primeiroNome);
        }
        // Não Localizado
        else {
          swalErroSimulacao(primeiroNome);
        }

      })
      .catch(function (error) {

        // Stop Loading
        setLoading(false);

        if (error.response) {

            // Desfragmentação do erro
            const {
              status
            } = error.response;
  
            // Não autorizado
            if(status == 401){
              // Swal de notificação
              AutenticacaoInvalida();
            }
            // Erro generico
            else {
              Toast.fire({
                icon: 'warning',
                title: 'Ops, tivemos um problema!'
              });
            }
            
        }
        // Erro generico
        else {
          Toast.fire({
            icon: 'error',
            title: 'Simulação temporariamente indisponível!'
          });
        }
        
      });
  }

  return (
    <>
      {
        loading
          ?
          elementLoading :
          <>
            <HelmetProvider>
              <Helmet>
                <title>Pega Troco | Autorização Saque FGTS</title>
              </Helmet>
            </HelmetProvider>
            <div className="appHeader">
              <div className="left">
                <a href="/" className="headerButton goBack">
                  <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
              </div>
              <div className="pageTitle">
                Autorização Saque FGTS
              </div>
            </div>
            <div id="appCapsule">
              <div className="section mb-2">
                <div className="row mt-2 justify-content-center">
                  <div className="col-md-7 align-self-center">
                    <div className="bill-box">
                      <div className="price">Agora, precisamos consultar seu FGTS</div>
                      <p className="fs-6 text-dark">É só seguir os passos abaixo, fácil, rápido e seguro.</p>
                      <div className="row">
                        <ul className="listview image-listview transparent flush">
                          {/* Adesão ao saque aniversario */}
                          <li>
                            <div className="item">
                              <img src={Bolo} className="img-fluid" alt="Imagem de bolo" width="45" />
                              &nbsp;
                              &nbsp;
                              <div className="in">
                                <div>Você precisa <strong>aderir ao saque-aniversário</strong> no aplicativo do FGTS </div>
                              </div>
                            </div>
                          </li>
                          {/* Autorização do Banco */}
                          <li>
                            <div className="item align-items-start">
                              <img src={Banco} className="img-fluid" alt="Imagem do banco" width="45" />
                              &nbsp;
                              &nbsp;
                              <div className="flex flex-col text-start pt-1">
                                <div>Autorize nossos bancos parceiros:</div>
                                <br></br>
                                <ul>
                                  <li className="fw-bold">Facta Financeira S.A</li>
                                  <li className="fw-bold">Banco Itaú</li>
                                  <li className="fw-bold">Banco Pan S.A</li>
                                  <li className="fw-bold">Qi Sociedade de Crédito Direto S.A</li>
                                </ul>
                              </div>
                            </div>
                          </li>
                          {/* Realizar simulação */}
                          <li>
                            <div className="item">
                              <img src={Dinheiro} className="img-fluid" alt="Imagem de dinheiro" width="45" />
                              &nbsp;
                              &nbsp;
                              <div className="in">
                                <div>Agora é só você clicar no botão abaixo para <strong>realizar a simulação</strong></div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="row mt-5">
                        <div className="col">
                          <button className="btn btn-primary btn-lg" onClick={SwalAutorizacao}>
                            Realizei a autorização
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MenuFooter></MenuFooter>
          </>
      }
    </>
  );
}

export default Autorizacao;